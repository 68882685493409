
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { IOrderList, IOrderShow } from "@/services/models/orders";
import { OrderShowOneRequest } from "@/services/api/ApiOrder";

@Component({})
export default class OrderModal extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() order!: IOrderList;
  order_detailed: IOrderShow = {} as IOrderShow;
  @Watch("open_modal")
  async reloadPage() {
    const resp = await OrderShowOneRequest(this.order.id);
    this.order_detailed = resp;
  }
  close(): void {
    this.$emit("close_modal");
  }
}
