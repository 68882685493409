
import { Component, Vue, Watch } from "vue-property-decorator";
import { IOrderList } from "../../services/models/orders";
import { OrderListRequest } from "../../services/api/ApiOrder";
import { notificationError } from "@/utils/NotificationUtils";
import OrderModal from "./order-modal/OrderModal.vue";

@Component({ components: { OrderModal } })
export default class Home extends Vue {
  loading = false;
  openModal = false;
  items: IOrderList[] = [];
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  pages = 0;
  pageOptions: number[] = [10, 25, 50, 100];
  filter = "";
  countPage = 0;
  selectedOrder: IOrderList = {} as IOrderList;
  fields: { key: string; label: string }[] = [
    {
      key: "origin_regional",
      label: `Origem`
    },
    {
      key: "destination_regional",
      label: `Destino`
    },
    {
      key: "order_status",
      label: `Status`
    },
    {
      key: "actions",
      label: `${this.$t("cars.fields.actions")}`
    }
  ];

  @Watch("perPage")
  onPerpageChange() {
    this.orderList();
  }

  @Watch("currentPage")
  onCurrentPageChange() {
    this.orderList();
  }

  mounted(): void {
    this.orderList();
  }

  async orderList(): Promise<void> {
    this.loading = true;
    try {
      let response = await OrderListRequest(this.perPage, this.currentPage);
      this.items = response.data;
      this.totalRows = response.total;
      this.pages = response.last_page;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  showModal(): void {
    this.openModal = true;
  }

  closeModal(): void {
    this.openModal = false;
  }

  async showDetailModal(data: IOrderList): Promise<void> {
    try {
      this.selectedOrder = data;
      this.openModal = true;
    } catch (error) {
      console.error(error);
      notificationError(`${this.$t("cars.messages.error.message.searchUser")}`);
    }
  }

  showPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  onFiltered(filteredItems: IOrderList[]): void {
    if (filteredItems.length > 0) {
      this.countPage = Math.ceil(this.totalRows / this.perPage);
    } else {
      this.countPage = 1;
    }
  }

  reloadPage(): void {
    this.orderList();
  }
}
